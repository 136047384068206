import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class KurzFilterPipe implements PipeTransform {

  transform(items: any[], fieldName: string, searchText: string): any[] {
    if (!items) {
      return [];
    }

    if (!searchText) {
      return items;
    }

    return items.filter(item => {
      if (item?.[fieldName]) {
        return item[fieldName].includes(searchText);
      }
      return false;
    });
  }

}
